<template>
  <div class="page">
    <section>
      <div class="box">
        <span class="title">问卷结束，感谢您的参与!</span>
        <img class="banner" src="@image/wjjs.png" alt="" />
        <span v-if="type == 1" class="duration">获得调研礼金</span>
        <span v-else class="duration">未获得调研礼金</span>
        <span class="money">¥{{ price }}</span>
        <div v-if="type == 1">
          <div class="hint">
            <span class="prompt"
              >将会有人进行审核，审核通过将会有礼金打入您的账户.如未收到礼金请电话联系我们：</span
            >
            <span class="number">4009201081</span>
          </div>
        </div>
        <div v-else-if="type == 2">
          <div class="subTitle">不好意思，您不符合条件</div>
          <div class="hint">
            <span class="prompt">有任何问题请电话联系我们：</span>
            <span class="number">4009201081</span>
          </div>
        </div>
        <div v-else-if="type == 3">
          <div class="subTitle">不好意思，您来晚了，名额已满</div>
          <div class="hint">
            <span class="prompt">有任何问题请电话联系我们：</span>
            <span class="number">4009201081</span>
          </div>
        </div>
      </div>
      <span v-show="type == 1" class="statement"
        >特此声明:根据《中华人民共和国个人所得税法实施条例》国令第 707号)
        第六条第(二)项的规定，我们将为您代扣代缴本次调研礼
        金个税，您的本次调研实际收入不会减少。</span
      >
      <span @click="$router.push({ path: '/research' })" class="button"
        >进入更多调研</span
      >
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1, //1获得调研礼金   2您不符合条件   3您来晚了
      price: 0,
    };
  },
  mounted() {
    this.price = this.$route.query.price;
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.page {
  > section {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 560px;
      height: 620px;
      background: #ffffff;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(3, 219, 198, 0.2);
      margin: 50px 0 30px 0;
      font-size: 24px;
      color: $--themeColor;
      padding: 49px 123px 43px 123px;
      box-sizing: border-box;
      .banner {
        width: 304px;
        height: 248px;
        object-fit: cover;
        margin: 50px 0 33px 0;
      }
      .duration {
        font-size: 24px;
        color: #333;
        margin-bottom: 30px;
      }
      .money {
        font-size: 36px;
        margin-bottom: 20px;
      }
      .subTitle {
        font-size: 14px;
        color: #8f8f8f;
        text-align: center;
      }
      .hint {
        font-size: 14px;
        .prompt {
          color: #8f8f8f;
          line-height: 1.8;
        }
        .number {
          color: #333;
          text-decoration: underline;
        }
      }
    }
    .statement {
      font-size: 20px;
      color: #333333;
      width: 565px;
      line-height: 36px;
      margin-bottom: 20px;
    }
    .button {
      cursor: pointer;
      width: 384px;
      height: 46px;
      background: $--themeColor;
      border-radius: 23px;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
      line-height: 46px;
    }
  }
}
</style>